import React, { useState, useRef } from "react";
import { graphql, useStaticQuery, navigate } from "gatsby";
import axios from "axios";
import Spinner from "./Spinner";
const formAPIURL = `${process.env.GATSBY_CAREER_FORM_URL}`;

const CareerForm = () => {
    const careerOpenings = useStaticQuery(
        graphql`
            {
                allContentfulCareerOpenings(sort: { order: ASC, fields: jobTitle }) {
                    nodes {
                        jobTitle
                        id
                    }
                }
            }
        `
    );
    const referralData = ["Social", "Email", "Google", "Referral", "Others"];
    const formEl = useRef(null);
    const [formSubmitError, setSubmitError] = useState("");
    const [isLoading, setLoading] = useState(false);
    const [stepForm, setFormState] = useState({
        position: "",
        name: "",
        email: "",
        phone: "",
        linkedin: "",
        location: "",
        referral: "",
        resume: null,
        terms: false,
    });
    const [isSubmitted, setSubmit] = useState(false);
    const [validationErrors, setValidationError] = useState({
        nameError: "",
        emailError: "",
        phoneError: "",
        positionError: "",
        linkedinError: "",
        termsError: "",
    });

    function inputsHandler(e) {
        if (e.target.name === "resume") {
            setFormState((prevState) => ({
                ...prevState,
                [e.target.name]: e.target.files[0],
            }));
        } else {
            setFormState((prevState) => ({
                ...prevState,
                [e.target.name]: e.target.value,
            }));
        }
    }

    function formValidator() {
        let nameError = "";
        let emailError = "";
        let phoneError = "";
        let positionError = "";
        let termsError = "";
        if (!/^[a-zA-Z ]+$/.test(stepForm.name)) nameError = "Invalid Name";
        if (
            !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                stepForm.email
            )
        )
            emailError = "Invalid Email";
        if (!/^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(stepForm.phone))
            phoneError = "Invalid Phone number";

        if (!stepForm.name) nameError = "Name is required";
        if (!stepForm.email) emailError = "Email is required";
        if (!stepForm.phone) phoneError = "Phone number is required";
        if (!stepForm.position) positionError = "Position is required";
        if (!stepForm.terms) termsError = "Kindly agree to the Privacy Policy";
        if (nameError || emailError || phoneError || positionError || termsError) {
            setValidationError({ nameError, emailError, phoneError, positionError, termsError });
            return false;
        }
        return true;
    }

    function handleFormSubmit(event) {
        event.preventDefault();
        setSubmit(true);
        const isValid = formValidator();
        if (isValid) {
            setLoading(true);
            // reset validation errors
            setValidationError({
                nameError: "",
                emailError: "",
                phoneError: "",
                positionError: "",
                linkedinError: "",
                termsError: "",
            });
            setSubmitError("");
            const formdata = new FormData();
            formdata.append("name", stepForm.name);
            formdata.append("position", stepForm.position);
            formdata.append("phone", stepForm.phone);
            formdata.append("email", stepForm.email);
            formdata.append("linkedin", stepForm.linkedin);
            formdata.append("location", stepForm.location);
            formdata.append("referral", stepForm.referral);
            if (stepForm.resume) {
                formdata.append("resume", stepForm.resume);
            }
            axios
                .post(formAPIURL, formdata, {
                    headers: {
                        "content-type": "multipart/form-data",
                    },
                })
                .then((resp) => {
                    setSubmit(false);
                    setFormState({
                        position: "",
                        name: "",
                        email: "",
                        phone: "",
                        linkedin: "",
                        location: "",
                        referral: "",
                        resume: null,
                    });
                    setLoading(false);
                    navigate(`thank-you?jobTitle=${stepForm.position}`);
                })
                .catch((err) => {
                    setSubmit(false);
                    setLoading(false);
                    setSubmitError("Oops, Something went wrong. Please try again");
                });
        }
    }

    return (
        <div className="career-form__wrapper">
            <div className="career-form__container">
                <div className="career-form__title">What are you interested in?</div>
                <form
                    onSubmit={(e) => handleFormSubmit(e)}
                    ref={formEl}
                    className="career-form__form"
                    noValidate
                    autoComplete="off"
                >
                    <div className="input-row">
                        <div className="mat-group">
                            <input
                                type="text"
                                className={`${
                                    validationErrors.nameError && isSubmitted ? "error" : ""
                                }`}
                                name="name"
                                required
                                onChange={(e) => inputsHandler(e)}
                                autoComplete="off"
                                value={stepForm.name}
                            />
                            <span className="highlight"></span>
                            <span className="bar"></span>
                            <label htmlFor="name">Name*</label>
                            <div className="error-resp">{validationErrors.nameError}</div>
                        </div>
                        <div className="mat-group">
                            <input
                                type="text"
                                className={`${
                                    validationErrors.emailError && isSubmitted ? "error" : ""
                                }`}
                                name="email"
                                required
                                onChange={(e) => inputsHandler(e)}
                                value={stepForm.email}
                                autoComplete="off"
                            />
                            <span className="highlight"></span>
                            <span className="bar"></span>
                            <label htmlFor="email">Email*</label>
                            <div className="error-resp">{validationErrors.emailError}</div>
                        </div>
                    </div>
                    <div className="input-row">
                        <div className="mat-group">
                            <input
                                type="text"
                                className={`${
                                    validationErrors.phoneError && isSubmitted ? "error" : ""
                                }`}
                                name="phone"
                                required
                                onChange={(e) => inputsHandler(e)}
                                value={stepForm.phone}
                                autoComplete="off"
                            />
                            <span className="highlight"></span>
                            <span className="bar"></span>
                            <label htmlFor="phone">Phone number*</label>
                            <div className="error-resp">{validationErrors.phoneError}</div>
                        </div>
                        <div className="mat-group">
                            <select
                                name="position"
                                className={`${
                                    validationErrors.positionError && isSubmitted ? "error" : ""
                                }`}
                                required
                                onChange={(e) => inputsHandler(e)}
                                value={stepForm.position}
                            >
                                <option defaultValue="" disabled hidden></option>
                                {careerOpenings.allContentfulCareerOpenings.nodes.map((job) => (
                                    <option key={job.id} value={job.jobTitle}>
                                        {job.jobTitle}
                                    </option>
                                ))}
                            </select>
                            <span className="highlight"></span>
                            <span className="bar"></span>
                            <label htmlFor="position">Position*</label>
                            <div className="error-resp">{validationErrors.positionError}</div>
                        </div>
                    </div>

                    <div className="input-row">
                        <div className="mat-group">
                            <input
                                type="text"
                                name="linkedin"
                                required
                                onChange={(e) => inputsHandler(e)}
                                value={stepForm.linkedin}
                                autoComplete="off"
                            />
                            <span className="highlight"></span>
                            <span className="bar"></span>
                            <label htmlFor="linkedin">Linkedin Profile</label>
                        </div>
                        <div className="mat-group">
                            <input
                                type="text"
                                name="location"
                                required
                                onChange={(e) => inputsHandler(e)}
                                autoComplete="off"
                                value={stepForm.location}
                            />
                            <span className="highlight"></span>
                            <span className="bar"></span>
                            <label htmlFor="location">Location</label>
                        </div>
                    </div>
                    <div className="input-row">
                        <div className="mat-group">
                            <select
                                name="referral"
                                required
                                onChange={(e) => inputsHandler(e)}
                                value={stepForm.referral}
                            >
                                <option defaultValue="" disabled hidden></option>
                                {referralData.map((refer) => (
                                    <option value={refer}>{refer}</option>
                                ))}
                            </select>
                            <span className="highlight"></span>
                            <span className="bar"></span>
                            <label htmlFor="referral">How did you know about us</label>
                        </div>
                        <div className="mat-group">
                            <input
                                type="file"
                                name="resume"
                                id="resume"
                                accept=".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                onChange={(e) => inputsHandler(e)}
                            />
                            <span className="highlight"></span>
                            <span className="bar"></span>
                            <label htmlFor="resume">Upload Resume</label>
                        </div>
                    </div>
                    <div className="mat-group ">
                        <div className="terms-condition-block">
                            <input
                                type="checkbox"
                                name="terms"
                                required="required"
                                onChange={(e) => inputsHandler(e)}
                                className="terms-conditions"
                            />
                            {/* <span className="checkmark"></span> */}
                            <span className="terms-conditions-text text-p6">
                                "I agree to share my information with Sedin and understand it will
                                be used as described in their Privacy Policy."
                            </span>
                        </div>
                        <div className="error-resp">{validationErrors.termsError}</div>
                    </div>
                    <div className="form-error">{formSubmitError}</div>
                    <button className="sdn-cta-btn next" type="submit">
                        <span>Submit</span>
                        <Spinner loading={isLoading} />
                    </button>
                </form>
            </div>
        </div>
    );
};

export default CareerForm;
