import * as React from "react";
import Layout from "../../../components/Layout";
import Seo from "../../../components/seo";
import CareerSlider from "../../../components/CareerSlider";
import CareerForm from "../../../components/CareerForm";

const CareerApply = ({ location }) => {
    return (
        <Layout>
            <Seo title="Careers" url={location.href} />
            <div className="container container--py">
                <CareerForm />
            </div>
            <section className="career">
                <div className="container container--last">
                    <div className="text">
                        <h2 className="text-h1 text-fw-medium text-clr-primary gap-4x">
                            Explore all open positions.
                        </h2>
                    </div>
                    <div className="career-slider-wrapper">
                        <CareerSlider></CareerSlider>
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default CareerApply;
